
let playVideoLightboxButton = document.querySelectorAll('.media-and-text--media_play');
playVideoLightboxButton.forEach((el, index) => {
    el.addEventListener( 'click', openVideoLightBox );
});

function openVideoLightBox(e) {

    let target   = e.currentTarget;
    let video    = target.getAttribute('data-video');
    let provider = target.getAttribute('data-provider');

    if (provider == 'youtube') {
        lightboxContent = '<iframe src="https://www.youtube.com/embed/' + video + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
    } else if (provider == 'vimeo') {
        lightboxContent = '<iframe src="https://player.vimeo.com/video/' + video + '?autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>';
    } else {
       lightboxContent = `
            <video controls autoplay>
                <source src="` + video + `" type="video/mp4">
            </video>
        `;
    }

     const instance = basicLightbox.create(lightboxContent);

    instance.show()
}